import { useContext, useEffect, useState } from "react";
import item from "./img/item.svg";
import "./style.css";
import { UserContext } from "../../Context/user";
import { getShop, getUserSell } from "../../Api/Api";
export default function Buy() {
  const [sells, setSells] = useState();
  const [user, setUser] = useContext(UserContext);
  const [shops, setShops] = useState();

  useEffect(() => {
    if (user && user.ID) {
      getUserSell(user.ID).then((data) => {
        setSells(data);
      });
    }
    getShop().then((data) => {
      setShops(data);
    });
  }, [user]);
  return (
    <div className="buy">
      <div className="nav" onClick={() => window.history.back()}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 7L9 13L15 19"
            stroke="#474753"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p>Покупки</p>
      </div>
      <div className="buyItems">
        {sells &&
          sells.length > 0 &&
          sells.map((em, index) => {
            let date = new Date(em.operation.TimeOperation);
            return (
              <div className="buyItem" key={`buyItem${index}`}>
                <div className="balls">
                  <p>Баллы</p>
                  <span>
                    {(
                      (em.operation.Summ / 100) *
                      em.shop.SaleForClients
                    ).toFixed(0)}
                  </span>
                </div>
                <div className="rightBuyItem">
                  <span>
                    {date.getDate() +
                      "." +
                      date.getMonth() +
                      "." +
                      date.getFullYear()}
                  </span>
                  <p>{em.shop.Name} </p>
                  <div>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="20" height="20" rx="10" fill="#F61A41" />
                      <path
                        d="M7.02734 9.99982L9.18951 12.162L13.7841 7.56738"
                        stroke="white"
                        stroke-width="1.62162"
                      />
                    </svg>
                    <p>Баллы зачислены</p>
                    <span>{em.operation.Summ} ₽</span>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
