import "./style.css";
import logo from "../Partners/img/logo.png";
import { useEffect, useState } from "react";
import { getShopForPage } from "../../Api/Api";
export default function PartnerPage() {
  const url = new URL(window.location);
  const id = url.searchParams.get("key");
  const [ptData, setPtData] = useState(null);
  console.log(ptData);
  useEffect(() => {
    getShopForPage(id).then((data) => {
      setPtData(data[0]);
    });
  }, []);
  return (
    <div className="PartnerPage">
      <div className="nav" onClick={() => window.history.back()}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 7L9 13L15 19"
            stroke="#474753"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p>Все партнеры</p>
      </div>
      <h2>Рубрика</h2>
      <div className="partnerItem">
        <img src={ptData && ptData.Image} alt="" />
        <p>{ptData && ptData.Name}</p>
        <div className="sl">{ptData && ptData.SaleForClients}%</div>
      </div>
      <p className="partnerDesc">{ptData && ptData.Description}</p>
      {ptData && ptData.Tel && (
        <div className="ctInf">
          <p>Телефон</p>
          <a href={`tel:${ptData && ptData.Tel}`}>{ptData && ptData.Tel}</a>
        </div>
      )}
      {ptData && ptData.City && (
        <div className="ctInf">
          <p>Адрес</p>
          <span>{ptData && ptData.City}</span>
        </div>
      )}
      {ptData && ptData.WorkSchedule && (
        <div className="ctInf">
          <p>График работы</p>
          <span>{ptData && ptData.WorkSchedule}</span>
        </div>
      )}
      {ptData && ptData.WebSyte && (
        <div className="ctInf">
          <p>Вебсайт</p>
          <span
            onClick={() => window.open(ptData ? ptData.WebSyte : "", "_blank")}
          >
            {ptData && ptData.WebSyte}
          </span>
        </div>
      )}
    </div>
  );
}
